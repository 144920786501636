@import '../base-shape/mixins';

@include shapeTemplate(polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%), 100%, 50%, unset, 0%, 25%);

.indicatorBar {
  right: inherit !important;
  top: inherit !important;
  bottom: 0;
  left: 0;
  margin-left: 25%;
  margin-bottom: 1px;
  align-items: flex-end;
  display: flex;
}

.resizableHandle {
  right: 25%;
  position: absolute;
  width: 8px;
  height: 8px;
  padding: 0 !important;
  bottom: 3px;
}

.pickerPosition {
  top: 100%;
  left: 25%;
}

.content {
  padding: $shape-padding 0;
}
