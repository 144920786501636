.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 8px;
  flex-flow: wrap;
  .left,
  .right {
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
  }

  .left {
    width: 65%;
    align-items: flex-start;

    .leftBottom {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      width: 100%;

      .youTube {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .right {
    width: 35%;
    .rigtTop {
      display: flex;
      width: 100%;
      max-height: 50%;
      height: 50%;
      position: relative;
      justify-content: flex-end;
      img {
        max-height: 300px;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
    .rigtBottom {
      max-height: 50%;
      padding-top: 8px;
      width: 100%;
      height: 50%;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      img {
        max-height: 150px;
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }
}
