.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  .wrapper {
    display: flex;
    width: 100%;
    flex: 1;
    :global {
      .side {
        position: relative;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
    .diagram {
      flex: 1;
      width: 100%;
    }
    .content {
      flex: auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      .editorWrapper {
        z-index: 999;
        display: flex;
        flex: 1;
      }
    }
  }
}
