:root {
  --dash-color: #000;
  --segment-size: 25px;
  --dash-filled: 60%;
}

.horizontal {
  cursor: n-resize;
  height: 100%;
  background-image: linear-gradient(to right, var(--dash-color) var(--dash-filled), transparent 0%);
  background-position: left;
  background-size: var(--segment-size) 100%;
  background-repeat: repeat-x;
}

.vertical {
  cursor: e-resize;
  height: 100%;
  background-image: linear-gradient(to bottom, var(--dash-color) var(--dash-filled), transparent 0%);
  background-position: top;
  background-size: 100% var(--segment-size);
  background-repeat: repeat-y;
}