@import '../../../../../theme/varibles';

.indicatorsTextField {
  margin-bottom: 16px !important;
  width: 100%;
}

.gray {
  color: $gray !important;
}
