.indicatorBox {
  height: 100%;
  position: absolute;
  left: 100%;
  bottom: 0;
  width: 0;
  display: none;
  transition: width 2s;
  overflow: auto;
  max-width: 205px;
  :global {
    .container {
      text-align: end;
      height: 100%;
      background: #ededff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      position: relative;
      width: 100%;

      .title {
        padding: 4px;
        margin: 0;
        color: #fff;
        font-size: 12px;
        background-color: #6a6a6a;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        position: fixed;
        width: 100%;
        max-width: 205px;
      }

      .list {
        padding: 4px;
        list-style: none;
        margin: 0;
        padding-top: 20px;
        height: 100%;
        overflow: auto;
        width: 100%;
        li {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .text {
            list-style-position: inside;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
          .status {
            display: block;
          }
        }
      }
    }
  }
}

.dBlock {
  display: block;
  width: 100%;
}
