.wrapper {
  background-color: white;
  height: 100%;
  .colorPicker {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1px;
    background-color: white;
    width: 19px;
    .selectedColor {
      width: 15px;
      height: 15px;
      border-radius: 2px;
    }
  }
  .reactQuill {
    margin-top: 8px;
    margin-bottom: 16px;
    :global {
      .ql-toolbar.ql-snow {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      .ql-container.ql-snow {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .ql-editor {
        overflow: auto;
        height: 200px;
      }
      .ql-snow {
        .ql-picker.ql-font {
          width: auto;
          min-width: 108px;
          .ql-picker-label {
            padding-right: 18px;
          }
        }
      }
      .ql-size {
        .ql-picker-label {
          padding-right: 18px;
        }
      }
    }
    .ql-size {
      .ql-picker-label {
        padding-right: 18px;
      }
    }
    .ql-picker-options {
      z-index: 2 !important;
    }
  }
}
