.colorPicker {
  .colorPickerBtn {
    min-width: 24px;
    height: 24px;
    padding: 0;
  }
  .picker {
    position: absolute;
    position: absolute;
    z-index: 9999;
    margin-left: 0px;
    margin-top: 5px;
  }
}
