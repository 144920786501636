:global {
  .indicatorsWrapper {
    position: absolute;
    z-index: 9999;
    .indicators {
      bottom: 0;
      align-items: center;
      width: 100%;
      .colorPicker {
        cursor: pointer;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 1px;
        background-color: white;
        width: 19px;
        .selectedColor {
          width: 15px;
          height: 15px;
          border-radius: 2px;
        }
      }
    }
    .indicatorsBar {
      opacity: 0;
      z-index: 9999;
      position: absolute;
      right: 1%;
      top: 5%;
    }
    &:hover .indicatorsBar {
      opacity: 1;
    }
  }
}

.pickerWrapper {
  position: absolute;
  z-index: 9999;
}
