.layout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

.main {
  display: flex;
  flex: 1;
}
