.editorLayout {
  z-index: 999;
  background-color: #f5f5f5;
  width: 400px;
  position: absolute;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
