@import '../../theme/varibles';

.wrapper {
  display: flex;
  padding-left: 8px;
  height: 100%;

  & ul {
    width: 40px;
    list-style: none;
    padding: 0;
    margin-top: 8px;
  }

  & li {
    height: 40px;
    width: 40px;
    color: $black;
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    cursor: pointer;
    position: relative;

    &:hover {
      color: $gray;
    }

    &.active {
      background: white;
      color: $blue;
      box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5) !important;

      &:before {
        width: 2px;
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $blue;
        position: absolute;
      }
    }
  }

  & section {
    background-color: white;
    padding: 8px;
    width: 100%;
    position: relative;
  }
}

.deleteIcon {
  position: absolute !important;
  right: 0;
  top: 5px;
}
