.icon {
  /* Origin class: .MuiSvgIcon-root*/
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2.4rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  & path {
    stroke: rgba(0, 0, 0, 0.38);
  }
}