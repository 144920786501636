@mixin input-placeholder {
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

.bg {
  background: #123a64;
  width: 100vw;
  height: 100vh;
}

.dialogWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 450px;
  border-radius: 3px;

  & .dialogBg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(https://www.theoryofchange.org/wp-content/uploads/2021/01/compass3.gif) 50% 25% no-repeat;
    background-size: 50%;
  }

  & .dialog {
    position: relative;
    z-index: 1;
  }

  & form {
    width: 100%;
    height: 100%;
    padding: 15px 20px;
  }
  & h2 {
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    letter-spacing: 5px;
    margin-bottom: 95px;
  }
  & input {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    background: rgba(140, 140, 140, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 15px;
    color: #fff;
    border-radius: 2px;
    font-size: 14px;
    &:focus {
      border: 1px solid rgba(255, 255, 255, 0.8);
      outline: none;
    }
    @include input-placeholder {
      color: #ddd;
    }
  }

  & .submitBtn {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 15px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.9);
    color: #444;
    font-size: 15px;
    margin-top: 40px;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.errorWrapper {
  position: relative;
  & .error {
    position: absolute;
    left: 0;
    top: 0;
    color: red;
  }
}
