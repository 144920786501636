@import '../base-shape/mixins';

@include shapeTemplate(ellipse(50% 50% at 50% 50%), 70%, 70%, center, 15%, 15%);

.indicatorBar {
  right: 6% !important;
  top: 36% !important;
}

.pickerPosition {
  left: 95%;
  top: 37%;
}

.resizableHandle {
  bottom: 14%;
  right: 13%;
}
