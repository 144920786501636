.tab {
  position: relative;
}

.label {
  display: inline-block;
  width: 100%;
}

.title {
  max-width: calc(100% - 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-transform: initial;
}

.icon {
  position: absolute;
  top: 10px;
  right: 2px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.tabPanel {
  display: grid !important;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5) !important;
  z-index: 998 !important;
  background-color: #f8fafb !important;
}
