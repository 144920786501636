@import '../../theme/varibles';
@import '../../theme/media';

.toolbarWrapper {
  border-bottom: 1px solid $gray;
  display: flex;
  padding: 8px;

  @include notebook {
    padding: 4px 8px;
  }

  :global {
    @include notebook {
      .MuiButton-root {
        min-width: 40px;
      }
      .MuiToggleButtonGroup-root {
        max-height: 36px;
        align-self: center;
      }
    }
    .MuiToggleButton-sizeSmall {
      padding: 5px !important;
    }
  }
}

.toolBarToggle {
  margin-left: auto;
}