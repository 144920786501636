@import '../../../theme/varibles';

footer {
  flex: 0 0 30px;
  padding: 8px 16px;
  background-color: $blue;
  color: $white;
  border-top: 1px solid $gray;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to left, #043b8e, #1976d2, #15a2f4);
  font-size: smaller;
}
