.wrapper {
  display: flex;
  & .checkbox {
    margin-left: 8px;
    margin-right: -8px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  & img {
    height: auto;
  }
}
