@import '_varibles.scss';

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.fullWidth {
  height: 100%;
  width: 100%;
}

p {
  margin: 0;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0 !important;
}

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

.ql-picker.ql-font .ql-picker-label[data-value='Roboto']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Roboto']::before {
  font-family: 'Roboto', cursive;
  content: 'Roboto' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='CourierPrime']::before,
.ql-picker.ql-font .ql-picker-item[data-value='CourierPrime']::before {
  font-family: 'Courier Prime', monospace;
  content: 'Courier Prime' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Montserrat']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Montserrat']::before {
  font-family: 'Montserrat', cursive;
  content: 'Montserrat' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Lato']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Lato']::before {
  font-family: 'Lato', cursive;
  content: 'Lato' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Verdana']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Verdana']::before {
  font-family: 'Verdana', cursive;
  content: 'Verdana' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Lora']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Lora']::before {
  font-family: 'Lora', serif;
  content: 'Lora' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='FiraSans']::before,
.ql-picker.ql-font .ql-picker-item[data-value='FiraSans']::before {
  font-family: 'Fira Sans', sans-serif;
  content: 'Fira Sans' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Merriweather']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Merriweather']::before {
  font-family: 'Merriweather', serif;
  content: 'Merriweather' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='PlayfairDisplay']::before,
.ql-picker.ql-font .ql-picker-item[data-value='PlayfairDisplay']::before {
  font-family: 'Playfair Display', serif;
  content: 'Playfair Display' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='ArchitectsDaughter']::before,
.ql-picker.ql-font .ql-picker-item[data-value='ArchitectsDaughter']::before {
  font-family: 'Architects Daughter', cursive;
  content: 'Architects Daughter' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Raleway']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Raleway']::before {
  font-family: 'Raleway', sans-serif;
  content: 'Raleway' !important;
}

.ql-font-Merriweather {
  font-family: 'Merriweather', serif;
}

.ql-font-PlayfairDisplay {
  font-family: 'Playfair Display', serif;
}

.ql-font-ArchitectsDaughter {
  font-family: 'Architects Daughter', cursive;
}

.ql-font-Raleway {
  font-family: 'Raleway', sans-serif;
}

.ql-font-Roboto {
  font-family: 'Roboto', sans-serif;
}
.ql-font-CourierPrime {
  font-family: 'Courier Prime', cursive;
}
.ql-font-Montserrat {
  font-family: 'Montserrat', sans-serif;
}
.ql-font-Lato {
  font-family: 'Lato', sans-serif;
}
.ql-font-Lora {
  font-family: 'Lora', serif;
}
.ql-font-FiraSans {
  font-family: 'Fira Sans', sans-serif;
}

.react-flow__node.selected {
  .selected {
    filter: drop-shadow(0px 0px 12px #00000080);
  }
}

.react-flow__edge.selected {
  path:nth-of-type(2) {
    stroke: rgba(152, 152, 152, 0.5);
  }
}

.react-flow__edges {
  z-index: 5 !important; /* lines over shapes */
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.bl {
  border-left: 1px solid $gray;
}

.br {
  border-right: 1px solid $gray;
}

.labelGgay {
  color: $grayText;
}

.bg-white {
  background-color: $white;
}

.btnCapitalize {
  text-transform: capitalize;
}

.MuiTab-root {
  &.Mui-selected {
    background-color: white !important;
    box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.35);
  }
}
