.callOut {
  background: #ff9966;
  height: 100%;
  padding: 8px;
  font-size: 10px;
  overflow: auto;
  border-radius: 4px;
  & .text {

  }
}
