.comment {
  background: #fffca5;
  height: 100%;
  padding: 8px;
  font-size: 10px;
  display: flex;
  flex-direction: column;

  &.isActKnowledgeComment {
    background: #366196;
  }
  & .creator {
    font-family: 'Verdana', sans-serif;
    font-weight: bold;
    font-size: 8px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & .scrollWrapper {
    width: 100%;
    height: 100%;
  }
  & .textarea {
    display: inline-block;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    background: transparent;
  }
}
