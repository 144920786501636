:root {
  --overlay-color: #0003;
}

.spinner {
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 5;
}

.overlay {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  animation: appear 1s;
  background: transparent;
}
@keyframes appear {
  0%   {background: transparent}
  100%   {background: var(--overlay-color)}
}
