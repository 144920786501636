@import '../base-shape/mixins';

.box {
  @include box();
}
.shadow {
  @include shadow();
}
.shape {
  @include shape(none);
}
.content {
  padding: $shape-padding;
  @include content(100%, 100%);
}

@include handlerSize();

.rounded {
  border-radius: 5px;
}

.pickerPosition {
  top: 5%;
  left: 100%;
}
