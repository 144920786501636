.sideLeft {
  flex: 0 0 20%;
  min-width: 431px;

  button[name='ChevronLeft'] {
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
