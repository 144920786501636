@import '../base-shape/mixins';

@include shapeTemplate(polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%), 75%, 65%, unset, 25%, 18%);

@include overrideLibraryStyles {
  .left {
    left: 3.5%;
  }
  .right {
    right: 3.5%;
  }
}

.indicatorBar {
  right: inherit !important;
  top: inherit !important;
  bottom: 0;
  left: 0;
  margin-left: 18%;
  margin-bottom: 1px;
  align-items: flex-end;
  display: flex;
}

.resizableHandle {
  right: 18%;
  position: absolute;
}

.pickerPosition {
  top: 100%;
  left: 18%;
}
