.shapes {
  margin-left: 5px;
}
.colorPicker {
  .colorPickerBtn {
    min-width: 24px !important;
    margin-right: 8px;
    margin-left: 8px;
    padding: 0;
    height: 24px;
  }
  .picker {
    position: absolute;
    position: absolute;
    z-index: 9999;
    margin-left: 8px;
    margin-top: 5px;
  }
}
