.form {
  .titleWrapper {
    padding-bottom: 0;
  }
  .closeIcon {
    position: absolute !important;
    right: 0;
    top: 0;
  }
  .notice {
    margin: 2.2rem;
  }
  .nameField,
  .outcomeField {
    min-height: 6.4rem; /* space for error */
  }
  .orientationField {
    margin-bottom: 1.4rem;
  }
  .actions {
    padding: 1rem 2.4rem 2.4rem;
  }
  .btn {
    padding: 0.3rem 3.6rem;
    text-transform: none;
  }
}
