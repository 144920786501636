@import '../../../theme/varibles';

.header {
  :global {
    display: flex;
    flex: 0 0 77px;
    justify-content: flex-end;
    align-items: center;
    background-color: $blue;
    color: $white;
    border-bottom: 2px solid #c6c8c9;
    background-image: linear-gradient(to right, #043b8e, #1976d2, #15a2f4);

    & .logo {
      align-items: center;
      &__img {
        height: 70px;
      }
    }

    & .nav-btn {
      text-align: center;

      .MuiButtonBase-root {
        border-radius: 50%;
        padding: 8px;
        width: 40px;
        height: 40px;
        min-width: auto;
        margin-bottom: 8px;
        background-color: #0662bd;

        &.Mui-disabled {
          border: 1px solid rgba(0, 0, 0, 0.12);
        }

        .trainingIcon {
          img {
            object-fit: none;
          }
        }
      }
      .MuiButton-outlinedSecondary {
        border: 2px solid rgba(245, 0, 87, 0.5);
        border-color: #6abcf9;
      }
    }
  }
}
