.preview {
  cursor: pointer;
  display: flex;
  background-color: white;
  border-radius: 4px;

  & .cell {
    font-size: 10px;
    color: #071933;
    padding: 0.3rem 0.6rem;
    border: 1px solid #071933;
    border-left: none;
    font-style: normal;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: flex;
    align-items: center;

    :global .ql-editor {
      padding: 0 !important;
    }

    &:first-child {
      border-left: 1px solid #071933;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
