$shape-padding: 8px;

@mixin box() {
  height: 100%;
  width: 100%;
  z-index: 9999;
}

@mixin shadow() {
  filter: drop-shadow(0px 0px 1px #000000);
  height: 100%;
  width: 100%;
}

@mixin shape($clipPath) {
  height: 100%;
  width: 100%;
  overflow: hidden;
  clip-path: $clipPath;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@mixin content($height, $width, $alignSelf: flex-end, $top: 0%, $left: 0%) {
  height: $height;
  width: $width;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-items: center;
  word-break: break-word;
  align-self: $alignSelf;
  position: absolute;
  top: $top;
  left: $left;
}

@mixin overrideLibraryStyles() {
  :global(.react-flow) {
    @content;
  }
}

@mixin handlerSize() {
  @include overrideLibraryStyles {
    .left,
    .right,
    .top,
    .bottom {
      width: 8px;
      height: 8px;
    }
    .left {
      left: 0;
      transform: translate(-50%) translateY(-50%);
    }
    .right {
      right: 0;
      transform: translate(50%) translateY(-50%);
    }
    .top {
      top: 0;
      transform: translate(-50%) translateY(-50%);
    }
    .bottom {
      bottom: 0;
      transform: translate(-50%) translateY(50%);
    }
  }
}

@mixin shapeTemplate($clipPath, $height, $width, $alignSelf, $top, $left) {
  .box {
    @include box();

    .shadow {
      @include shadow();

      .shape {
        @include shape($clipPath);
      }
    }

    .content {
      @include content($height, $width, $alignSelf, $top, $left);
    }
  }
  @include handlerSize();
}
