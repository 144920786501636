.textBox {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  font-size: 10px;
  overflow: auto;
  & .creator {
    font-family: 'Verdana', sans-serif;
    font-weight: bold;
  }
}
