@import '../../../../theme/varibles';

/*
  Height is also calculated in javascript. Pay attention: src/pages/layout/pages/dashboard/scheme-parser/xml-to-js/color-legend.ts
*/

.title {
  font-family: 'Verdana', sans-serif;
  font-weight: bold;
  background: $blue;
  padding: 4px 4px 0 4px;
  height: 26px; /* When you edit it please also change height calculation in js */
  color: white;
}

.list {
  padding: 14px 0 14px 14px; /* When you edit it please also change height calculation in js */
  border: 1px solid $blue;
  background: #1976d21a;

  & .row {
    margin-bottom: 4px; /* When you edit it please also change height calculation in js */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    & .colorPreview {
      flex: 0 0 20px;
      height: 20px; /* When you edit it please also change height calculation in js */
      margin-right: 10px;
    }

    & .text {
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid rgba(0, 0, 0, 0.5);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 0;
      outline: 0;
      &:hover, &:focus {
        border: 1px solid rgba(0, 0, 0, 0.75);
        background: rgba(255, 255, 255, 1);
      }
    }

    & .remove {
      padding: 0 4px;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
}