@import '../base-shape/mixins';

@include shapeTemplate(polygon(50% 0%, 0% 100%, 100% 100%), 60%, 40%, unset, 40%, 30%);

.indicatorBar {
  right: inherit !important;
  top: inherit !important;
  bottom: 0;
  left: 0;
  margin-left: 15px;
  margin-bottom: 3px;
  align-items: flex-end;
  display: flex;
}

@include overrideLibraryStyles {
  .left {
    left: 24.5%;
  }

  .right {
    right: 24.5%;
  }
}

.resizableHandle {
  right: 3% !important;
}
