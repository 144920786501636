@import '../base-shape/mixins';

@include shapeTemplate(
  polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%),
  80%,
  60%,
  center,
  10%,
  20%
);

.indicatorBar {
  top: 30% !important;
}

.pickerPosition {
  top: 30%;
  left: 100%;
}

.resizableHandle {
  right: 30%;
  position: absolute;
  width: 8px;
  height: 8px;
  padding: 0 !important;
  bottom: 3px;
}
