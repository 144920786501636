.tools {
  display: flex;
  align-items: center;

  :global .MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
  }
}

.delete {
  padding: 8px !important;
}
