.reactQuill {
  margin-top: 8px;
  margin-bottom: 8px;
  max-width: 100%;
  :global {
    .ql-toolbar.ql-snow {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    .ql-container.ql-snow {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .ql-editor {
      overflow: auto;
      height: 200px;
    }
    .ql-snow {
      .ql-picker.ql-font {
        width: auto;
        min-width: 108px;
        .ql-picker-label {
          padding-right: 18px;
        }
      }
    }
    .ql-size {
      .ql-picker-label {
        padding-right: 18px;
      }
    }
    .ql-size {
      .ql-picker-label {
        padding-right: 18px;
      }
    }
    .ql-picker-options {
      z-index: 2 !important;
    }
  }
}
