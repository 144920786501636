@import '../../../../../theme/varibles';

.tocoTable {
  :global {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border: 0 !important;

    .MuiDataGrid-root .MuiDataGrid-columnSeparator {
      right: -8px;
    }

    .grid {
      height: calc(100% - 137px);

      .MuiDataGrid-root {
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;

        .MuiDataGrid-columnsContainer {
          background-color: #e9f0f4;
        }
        .MuiDataGrid-row {
          &.Mui-selected {
            background-color: #d5dfe4;
          }
        }
        .header {
          .MuiDataGrid-colCellTitle {
            font-weight: 600;
          }
        }

        .table-cell-truncate {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .MuiDataGrid-footer {
          background-color: #e9f0f4;
          justify-content: flex-end;

          .MuiTablePagination-toolbar {
            padding: 0;
          }
        }

        .MuiDataGrid-window {
          overflow-x: hidden;
        }
      }
    }

    .search-box {
      .wrapper {
        height: 48px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $gray;

        .input {
          width: calc(100% - 48px);
          padding-right: 8px;

          &:after {
            position: absolute;
            right: 0;
            top: 10px;
            height: 30px;
            content: ' ';
            border-right: 1px $gray solid;
          }

          .MuiInputBase-input {
            padding-left: 16px;
            height: 36px;
          }

          .MuiInput-underline:before {
            border-bottom: 1px solid rgba(0, 0, 0, 0);
          }
        }
      }

      .radio {
        font-size: 16px;
        display: flex;
        align-items: center;

        .MuiTypography-body1 {
          font-size: 13px;
        }
      }
    }
  }
  .noRows {
    flex-direction: column;
  }

  .labelNoToC {
    margin-top: 8px;
  }
}
